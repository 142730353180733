<template>
    <footer>
        <a href="assets/frontend/files/politykaprywatnosci.pdf" target="_blank" class="d-block d-sm-inline-block">Polityka prywatności</a>
        <span class="d-none d-sm-inline-block mx-2">|</span>
        <a href="assets/frontend/files/cookies.pdf" target="_blank" class="d-block d-sm-inline-block">Cookies</a>
        <span class="d-none d-sm-inline-block mx-2">|</span>
        <a href="assets/frontend/files/regulamin.pdf" target="_blank" class="d-block d-sm-inline-block">Regulamin</a>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
}
</script>

<style scoped lang="scss">
footer {
    padding: 12px 30px;
    text-align: center;
    background: #141413;
    color: #fff;
    position: relative;
    a{
        color: #fff;
        &:hover{
            color: #C8A666;
        }
    }
}
</style>
