import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VueMask from 'v-mask'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueGtag from "vue-gtag";
Vue.use(VueMask);
Vue.use(VueDOMPurifyHTML)

Vue.use(VueGtag, {
    config: { id: "G-K2P6WEC6B9" }
})

Vue.config.productionTip = false

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')
