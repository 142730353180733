import Vue from 'vue'
import Vuex from 'vuex'
import popup from './states/popup'

Vue.use(Vuex)

const index = new Vuex.Store({
  modules: {
    popup: popup,
  }
})

export default index
